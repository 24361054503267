import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { db } from "@/plugins/firebase";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    memberLoggedIn: false,
    adminLoggedIn: false,
    user: null,
    fuser: null,
    dark: false,
    token: null,
    refresh: false
  },
  mutations: {
    setRefresh(state, refresh) {
      Vue.set(state, "refresh", refresh);
    },
    setToken(state, token) {
      Vue.set(state, "token", token);
    },
    setFirebaseUser(state, fuser) {
      Vue.set(state, "fuser", fuser);
    },
    loginUser(state, user) {
      const DoB = new Date(
        user.DoB.split("/")
          .reverse()
          .join("/")
      );
      user.DoB = DoB;
      Vue.set(state, "user", user);
      Vue.set(state, "memberLoggedIn", true);
      Vue.set(state, "adminLoggedIn", false);

      db.collection("Members")
        .doc(user.Login)
        .set(
          {
            // Fields starting with CAPS should not be changed in the app
            FirstName: user.FirstName,
            Surname: user.Surname,
            CID: user.CID,
            Email: user.Email,
            Login: user.Login,
            MemberType: user.MemberType,
            DoB: DoB,
            Gender: user.Gender
          },
          { merge: true }
        );
      db.collection("Attendance")
        .doc(user.Login)
        .set(
          {
            // Fields starting with CAPS should not be changed in the app
            Login: user.Login
          },
          { merge: true }
        );
    },
    loginAdmin(state, user) {
      Vue.set(state, "user", user);
      Vue.set(state, "adminLoggedIn", true);
      Vue.set(state, "memberLoggedIn", false);
    },
    logout(state) {
      Vue.set(state, "user", null);
      Vue.set(state, "fuser", null);
      Vue.set(state, "memberLoggedIn", false);
      Vue.set(state, "adminLoggedIn", false);
    }
  },
  getters: {
    user: state => state.user,
    fuser: state => state.fuser,
    token: state => state.token,
    refresh: state => state.refresh
  },
  actions: {}
});
