<template>
  <v-btn ma-0 color="#007bff" @click="logout">Log Out</v-btn>
</template>

<script>
import { auth, db } from "@/plugins/firebase";
import store from "@/store";

export default {
  fiery: true,
  data: function() {
    return {
      devices: store.state.user.Login
        ? this.$fiery(
            db
              .collection("Members")
              .doc(store.state.user.Login)
              .collection("devices")
          )
        : {}
    };
  },
  methods: {
    logout() {
      if (store.state.token != null && store.state.memberLoggedIn) {
        const curDevice = this.devices.find(
          device => device.token === store.state.token
        );
        if (curDevice && curDevice.token) {
          this.$fiery
            .remove(curDevice)
            .then(() => {
              store.commit("setToken", null);
              store.commit("logout");
              auth.signOut();
              this.$router.push("/");
            })
            .catch(() => {
              store.commit("logout");
              auth.signOut();
              this.$router.push("/");
            });
        }
      } else {
        store.commit("logout");
        auth.signOut();
        this.$router.push("/");
      }
    }
  }
};
</script>
