import Vue from "vue";
import VueRouter from "vue-router";
import store from "./store";
import Login from "./views/Login";
import Member from "./views/Member";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/member",
      name: "member",
      component: Member,
      children: [
        {
          path: "id",
          name: "member-id",
          component: () => import("./views/Member/Id.vue")
        },
        {
          path: "events",
          name: "member-events",
          component: () => import("./views/Member/Events.vue")
        },
        {
          path: "score",
          name: "member-score",
          component: () => import("./views/Member/Score.vue")
        },
        {
          path: "news",
          name: "member-news",
          component: () => import("./views/Member/News.vue")
        },
        {
          path: "profile",
          name: "member-profile",
          component: () => import("./views/Member/Profile.vue")
        }
      ]
    },
    {
      path: "/admin",
      name: "admin",
      component: () => import("./views/Admin.vue"),
      children: [
        {
          path: "/admin/scan",
          name: "admin-scan",
          component: () => import("./views/Admin/Scan.vue")
        },
        {
          path: "/admin/events",
          name: "admin-events",
          component: () => import("./views/Admin/Events.vue")
        },
        {
          path: "/admin/score",
          name: "admin-score",
          component: () => import("./views/Admin/Score.vue")
        },
        {
          path: "/admin/news",
          name: "admin-news",
          component: () => import("./views/Admin/News.vue")
        }
      ]
    },
    {
      path: "/",
      name: "login",
      component: Login
    }
  ]
});

export default router;

router.beforeEach((to, from, next) => {
  if (to.name === null) {
    next("/");
  } else {
    const parentName = to.matched[0].name;
    if (
      store.state.memberLoggedIn &&
      (parentName != "member" || to.matched.length === 1)
    ) {
      // MEMBER that is not on member page OR is on JUST /member (not subPage)
      next("/member/id");
    } else if (
      store.state.adminLoggedIn &&
      (parentName != "admin" || to.matched.length === 1)
    ) {
      // ADMIN that is not on admin page OR is on JUST /admin (not subPage)
      next("/admin/scan");
    } else if (
      to.name != "login" &&
      !store.state.memberLoggedIn &&
      !store.state.adminLoggedIn
    ) {
      // NOT MEMBER and NOT ADMIN and NOT on /login go to login
      next("/");
    } else {
      next();
    }
  }
});
