import Vue from "vue";
import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";
import "firebase/messaging";
import FieryVue from "fiery-vue";
import store from "@/store";

Vue.use(FieryVue);

const firebaseConfig = {
  apiKey: "AIzaSyCMKU097u1d_UDqz4RNnXlQyvrol7GeRLI",
  authDomain: "sponsors-portal-docsoc.firebaseapp.com",
  databaseURL: "https://sponsors-portal-docsoc.firebaseio.com",
  projectId: "sponsors-portal-docsoc",
  storageBucket: "sponsors-portal-docsoc.appspot.com",
  messagingSenderId: "611914156004",
  appId: "1:611914156004:web:9ea32f3fed7d16ce38e9f1",
  measurementId: "G-3EX423PHZ6",
};

const app = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore(app);
export const auth = firebase.auth(app);
export const storage = firebase.storage().ref();
export const FieldValue = firebase.firestore.FieldValue;
let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(
    "BHnG2kNme5JO1Z-_zGpwD3CZ6Mzo-z3drpXBBRr5GvWhCcb3h-VhMyj9SeXTFF4ePOv14Z2Y70ixqEj42KkSjMI"
  );
}
export { messaging };


// Cashing
firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});
firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code == "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code == "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

// On login
auth.onAuthStateChanged((user) => {
  if (user) {
    store.commit("setFirebaseUser", user);
  } else {
    store.commit("setFirebaseUser", null);
  }
});
