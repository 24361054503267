<template>
  <v-app>
    <!-- App Bar -->
    <v-app-bar
      app
      fade-img-on-scroll
      dark
      :src="require('../assets/bg.jpg')"
      color="#225590"
    >
      <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          position="center center"
          gradient="to top right, rgba(34,85,144,.9), rgba(34,85,144,.7)"
          alt="gb"
        ></v-img>
      </template>

      <v-layout>
        <v-flex style="font-size: 1.8em">My DoCSoc</v-flex>
        <SignOut></SignOut>
      </v-layout>
    </v-app-bar>

    <v-main>
      <!-- Subpage -->
      <v-container fluid class="pb-10">
        <router-view />
      </v-container>

      <!-- Navigation -->
      <v-bottom-navigation shift fixed v-model="activePage">
        <v-btn
          v-for="page in pages"
          :key="page.path"
          :value="page.path"
          :to="page.path"
        >
          <span>{{ page.name }}</span>
          <v-icon>{{ page.icon }}</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </v-main>

    <!-- Notification -->
    <v-dialog v-model="notification" max-width="290">
      <v-card>
        <v-card-title class="headline">{{ newTitle }}</v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="green darken-1" text @click="newFunc"> Open </v-btn>

          <v-btn color="green darken-1" text @click="notification = false">
            Ignore
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import store from "@/store";
import SignOut from "@/components/SignOut";
import { db, messaging } from "@/plugins/firebase";

export default {
  fiery: true,
  data() {
    return {
      devices: this.$fiery(
        db
          .collection("Members")
          .doc(store.state.user.Login)
          .collection("devices")
      ),
      activePage: window.location.pathname,
      notification: false,
      newTitle: "",
      newFunc: () => {},
      pages: [
        {
          path: "/member/events",
          name: "Events",
          icon: "mdi-calendar",
        },
        {
          path: "/member/score",
          name: "Ranking",
          icon: "mdi-clipboard-text-outline",
        },
        {
          path: "/member/id",
          name: "ID",
          icon: "mdi-qrcode",
        },
        {
          path: "/member/profile",
          name: "Profile",
          icon: "mdi-account",
        },
        {
          path: "/member/news",
          name: "News",
          icon: "mdi-newspaper",
        },
      ],
    };
  },
  components: { SignOut },
  mounted() {
    // Request Permission of Notifications
    if (messaging != null) {
      messaging
        .getToken()
        .then((currentToken) => {
          if (currentToken) {
            this.sendTokenToServer(currentToken);
          } else {
            // Show permission request.
            // console.log(
            //   "No Instance ID token available. Request permission to generate one."
            // );
          }
        })
        .catch(() => {
          // console.log("An error occurred while retrieving token. ", err);
        });
      messaging.onTokenRefresh(() => {
        messaging
          .getToken()
          .then((refreshedToken) => {
            // console.log("Token refreshed.");
            // Indicate that the new Instance ID token has not yet been sent to the
            // app server.
            this.sendTokenToServer(refreshedToken);
            // Send Instance ID token to app server.
            // ...
          })
          .catch(() => {
            // console.log("Unable to retrieve refreshed token ", err);
          });
      });
      messaging.onMessage((payload) => {
        if (payload.data.type == "event") {
          this.notification = true;
          this.newTitle = "New Event!";
          this.newFunc = () => {
            this.$router.push("/member/events");
            this.notification = false;
          };
        }
        if (payload.data.type == "news") {
          this.notification = true;
          this.newTitle = "New News!";
          this.newFunc = () => {
            this.$router.push("/member/news");
            this.notification = false;
          };
        }
      });
    }
  },
  methods: {
    sendTokenToServer(token) {
      if (store.state.token === null) {
        this.$fires.devices.add({ token: token });
        store.commit("setToken", token);
      } else {
        const curDevice = this.devices.find(
          (device) => device.token == store.state.token
        );
        if (curDevice.token) {
          curDevice.token = token;
          store.commit("setToken", token);
          this.$fiery.save(curDevice);
        } else {
          this.$fires.devices.add({ token: token });
          store.commit("setToken", token);
        }
      }
    },
  },
};
</script>
