<template>
  <v-card flat height="100%">
    <v-img
      height="100%"
      gradient="to top right, rgba(34,85,144,.9), rgba(34,85,144,.7)"
      :src="require('../assets/bg.jpg')"
      alt="bg"
    >
      <v-container style="height: 100%" mt-n12>
        <v-layout justify-center align-center style="height: 100%">
          <v-flex xs12 xl6 md8>
            <v-card class="my-2" elevation="3">
              <div style="font-size: 1.8em" class="pa-3 text-center">
                My DoCSoc Portal
              </div>
            </v-card>
            <v-card :loading="loading" elevation="3">
              <v-form ref="form" @keyup.native.enter="submit">
                <v-card-title>Login</v-card-title>
                <v-card-text>
                  <v-text-field
                    ref="user"
                    v-model="user"
                    :rules="[() => !!user || 'This field is required']"
                    :error-messages="errorMessages"
                    label="Username"
                    placeholder="ab123"
                    required
                  ></v-text-field>
                  <v-text-field
                    ref="pass"
                    v-model="pass"
                    :append-icon="show ? 'visibility' : 'visibility_off'"
                    :type="show ? 'text' : 'password'"
                    :rules="[() => !!pass || 'This field is required']"
                    label="Password"
                    placeholder="Password"
                    @click:append="show = !show"
                    required
                  ></v-text-field>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    color="primary"
                    v-if="reset"
                    absolute
                    text
                    @click="resetPass"
                  >
                    Reset<br />password
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    class="px-10"
                    color="primary"
                    :loading="loading"
                    large
                    @click="submit"
                  >
                    Login
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-slide-x-reverse-transition>
                    <v-tooltip v-if="formHasErrors" left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          absolute
                          right
                          icon
                          class="my-0"
                          @click="resetForm"
                          v-on="on"
                        >
                          <v-icon>refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>Refresh form</span>
                    </v-tooltip>
                  </v-slide-x-reverse-transition>
                </v-card-actions>
              </v-form>
            </v-card>
            <v-snackbar v-model="snackbar" :timeout="10000" class="text-center">
              {{ snackbarText }}
            </v-snackbar>
          </v-flex>
        </v-layout>
      </v-container>
    </v-img>
  </v-card>
</template>

<script>
import rp from "request-promise";
import { db, auth } from "../plugins/firebase";
import store from "@/store";

export default {
  fiery: true,
  data: function () {
    return {
      show: false,
      user: "",
      pass: "",
      reset: false,
      loading: false,
      errorMessages: "",
      formHasErrors: false,
      snackbarText: "",
      snackbar: false,
      admin: this.$fiery(db.collection("Portal").doc("admin")),
    };
  },
  computed: {
    form() {
      return {
        user: this.user,
        pass: this.pass,
      };
    },
  },
  watch: {
    user() {
      this.errorMessages = "";
    },
  },
  methods: {
    resetPass() {
      this.snackbarText = "";
      this.snackbar = false;
      this.reset = false;

      if (this.user) {
        auth
          .sendPasswordResetEmail(this.user)
          .then(() => {
            this.snackbarText = "Successfully sent reset email";
            this.snackbar = true;
            this.$refs["pass"].reset();
          })
          .catch((error) => {
            this.snackbarText = error;
            this.snackbar = true;
          });
      }
    },
    resetForm() {
      this.errorMessages = [];
      this.formHasErrors = false;

      Object.keys(this.form).forEach((f) => {
        this.$refs[f].reset();
      });
      this.reset = false;
    },
    submit() {
      this.reset = false;
      this.formHasErrors = false;
      this.errorMessages = [];

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });
      if (!this.formHasErrors) {
        this.loading = true;
        auth
          .signInWithEmailAndPassword(this.user, this.pass)
          .then((data) => {
            if (data.user.uid === this.admin.user) {
              // admin login
              store.commit("loginAdmin", auth.currentUser);
              this.$router.push("/admin/scan");
            } else {
              // not admin email
              this.errorMessages = "Incorrect Username or Password";
              this.formHasErrors = true;
              this.loading = false;
            }
          })
          .catch((err) => {
            if (err.code === "auth/wrong-password") {
              // is sponsor with wrong pass
              this.loading = false;
              this.errorMessages = "Incorrect Password";
              this.formHasErrors = true;
              this.reset = true;
            } else {
              // user login
              const options = {
                method: "POST",
                uri: "https://func.docsoc.co.uk/.netlify/functions/firebase_auth",
                body: {
                  user: this.user.toLowerCase(),
                  pass: this.pass,
                },
                json: true,
              };
              rp(options)
                .then((res) => {
                  if (res.auth) {
                    auth.signInWithCustomToken(res.token).then(() => {
                      // user success
                      this.loading = false;
                      store.commit("loginUser", res.user);
                      this.$router.push("/member/id");
                    });
                  } else {
                    // user fail
                    this.errorMessages = "Incorrect Username or Password";
                    this.formHasErrors = true;
                    this.loading = false;
                  }
                })
                .catch(() => {
                  this.errorMessages =
                    "Login Servers are down. Please try again later";
                  this.formHasErrors = true;
                  this.loading = false;
                });
            }
          });
      }
    },
  },
};
</script>
