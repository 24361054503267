<template>
  <v-app>
    <router-view />
    <!-- Update -->
    <v-dialog v-model="refresh" max-width="290">
      <v-card>
        <v-card-title class="headline">New update is available</v-card-title>
        <v-card-text>
          Please refresh the page
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="hide">
            Done
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import store from "@/store";
export default {
  computed: {
    refresh() {
      return store.state.refresh;
    }
  },
  methods: {
    hide() {
      store.commit("refresh", false);
    }
  }
};
</script>

<style>
.v-application,
body {
  font-family: "Source Sans Pro", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
p {
  font-family: "Montserrat", Geneva, Verdana, sans-serif;
}
</style>
